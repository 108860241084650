import type { ReactNode } from 'react'
import Image from '@hashicorp/react-image'
import ReactHead from '@hashicorp/react-head'
import fragment from './fragment.graphql'
import { useMediaQuery } from '@web/hooks/use-media-query'

export interface Props {
	pathname: string | null
	title?: string
	description?: string
	image?: string
	twitterCard?: 'summary' | 'summary_large_image'
	stylesheet?: {
		href: string
		media?: string
		type?: string
	}[]
	icon?: ConcatArray<{
		href: string
		type?: string
		sizes?: string
	}>
	data?: {
		globalSeo?: {
			fallbackSeo?: {
				title?: string
				image?: {
					url: string
				}
				faviconMetaTags?: {
					[key: string]: unknown
					attributes: {
						[key: string]: unknown
						href: string
					}
				}[]
				twitterCard?: string
			}
		}
	}
	children?: ReactNode
}

function GlobalHeadTags({
	pathname,
	title,
	image,
	data,
	twitterCard,
	icon: additionalIcon,
	stylesheet: additionalStylesheet,
	...props
}: Props) {
	const fallbackData = data?.globalSeo?.fallbackSeo
	const stylesheet = additionalStylesheet || []
	const prefersDarkScheme = useMediaQuery('(prefers-color-scheme: dark)')

	let icon
	if (
		(process.env.HASHI_ENV === 'preview' ||
			process.env.HASHI_ENV === 'development') &&
		process.env.NODE_ENV === 'development'
	) {
		icon = [{ href: `/img/favicon-dev.ico` }]
	} else if (
		process.env.HASHI_ENV === 'preview' &&
		process.env.NODE_ENV === 'production'
	) {
		icon = [{ href: `/img/favicon-preview.ico` }]
	} else {
		icon = [
			{
				href: `/img/favicon-production-${
					prefersDarkScheme ? 'white' : 'black'
				}.ico`,
			},
		].concat(
			additionalIcon || [],
			Array.isArray(fallbackData?.faviconMetaTags)
				? fallbackData
					? fallbackData.faviconMetaTags.map((tag) => tag.attributes)
					: []
				: []
		)
	}

	const { children, ...rest } = props
	return (
		<ReactHead
			title={title || fallbackData?.title}
			pageName={title || fallbackData?.title}
			image={image || fallbackData?.image?.url}
			twitterCard={
				(twitterCard || fallbackData?.twitterCard || 'summary_large_image') as
					| 'summary'
					| 'summary_large_image'
			}
			siteName="HashiCorp"
			stylesheet={stylesheet}
			icon={icon}
			{...rest}
		>
			<meta
				name="facebook-domain-verification"
				content="ytxjjr2zmf03nrdbxa1xnqu7sfpw6n"
			/>
			<meta
				name="google-site-verification"
				content="M0j-klmlI446ZVnRqFQ25sbn6PK3W20b9gDvGMNZznE"
			/>
			<meta name="author" content="HashiCorp" />
			<meta name="generator" content="nextjs" />
			<meta name="theme-color" content="#000" />
			<meta name="mobile-web-app-capable" content="yes" />
			<meta name="apple-mobile-web-app-capable" content="yes" />
			<meta name="apple-mobile-web-app-status-bar-style" content="black" />
			{/* nextjs bug - on static exported dynamic pages, router renders the raw page route */}
			{/* initially, before re-rendering to the actual path, which trips up analytics */}
			{pathname && !pathname.includes('[') && (
				<link
					key="canonical"
					rel="canonical"
					href={`https://www.hashicorp.com${pathname}`}
				/>
			)}
			<link
				rel="alternate"
				href="https://www.hashicorp.com/blog/feed.xml"
				type="application/rss+xml"
				title="HashiCorp Blog"
			/>
			{children}
		</ReactHead>
	)
}

GlobalHeadTags.fragmentSpec = { fragment, dependencies: [Image] }

export default GlobalHeadTags
