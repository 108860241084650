import(/* webpackMode: "eager", webpackExports: ["EnFallbackEventLogger"] */ "/vercel/path0/apps/www/app/[locale]/components/en-fallback-event-logger/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/www/app/[locale]/conferences/style.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["AppRouterGlobalHeadTags"] */ "/vercel/path0/apps/www/components/GlobalHeadTags/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/www/components/lazy-motion-wrapper/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/www/components/skip-link/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
