'use client'

import s from './style.module.css'

const SkipLink = () => {
	return (
		<a className={s.root} href="#main">
			Skip to main content
		</a>
	)
}

export default SkipLink
